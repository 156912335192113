exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-about-ts": () => import("./../../../src/pages/about.ts" /* webpackChunkName: "component---src-pages-about-ts" */),
  "component---src-pages-confirm-ts": () => import("./../../../src/pages/confirm.ts" /* webpackChunkName: "component---src-pages-confirm-ts" */),
  "component---src-pages-index-ts": () => import("./../../../src/pages/index.ts" /* webpackChunkName: "component---src-pages-index-ts" */),
  "component---src-pages-login-ts": () => import("./../../../src/pages/login.ts" /* webpackChunkName: "component---src-pages-login-ts" */),
  "component---src-pages-order-complete-ts": () => import("./../../../src/pages/order/complete.ts" /* webpackChunkName: "component---src-pages-order-complete-ts" */),
  "component---src-pages-order-confirm-ts": () => import("./../../../src/pages/order/confirm.ts" /* webpackChunkName: "component---src-pages-order-confirm-ts" */),
  "component---src-pages-order-index-ts": () => import("./../../../src/pages/order/index.ts" /* webpackChunkName: "component---src-pages-order-index-ts" */),
  "component---src-pages-product-ts": () => import("./../../../src/pages/product.ts" /* webpackChunkName: "component---src-pages-product-ts" */)
}

